<template>
    <v-row justify="center">
        <v-card width="100%" height="100vh" v-show="skeleton">
            <v-sheet>
                <v-skeleton-loader width="100%" type="image"></v-skeleton-loader>
                <v-skeleton-loader width="100%" type="image"></v-skeleton-loader>
            </v-sheet>
            <v-sheet>
                <v-skeleton-loader
                    class="mx-auto"
                    width="70%"
                    type="article, list-item-three-line, article, 
                    list-item-three-line"
                ></v-skeleton-loader>
            </v-sheet>
        </v-card>
        <v-card v-show="!skeleton" min-width="100%" min-height="100vh">
            <v-img :src="imageNote" height="600">
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-col class="text-center" cols="12">
                        <h1 class="text-md-h2 text-h3 font-weight-bold mb-4 white--text">
                            {{ title }}
                        </h1>
                        <h5 class="subheading white--text">
                            <v-icon color="red">mdi-account</v-icon>{{ author }}
                            <v-icon color="red">mdi-calendar-month</v-icon>{{ date }}
                            <v-icon color="red">mdi-label</v-icon>{{ category }}
                        </h5>
                    </v-col>
                </v-row>
            </v-img>
            <v-card-text class="text-justify">
                <v-row align="center" class="mx-0" justify="center">
                    <v-col cols="12" sm="9">
                        <VueShowdown
                            flavor="github"
                            :markdown="mdSource"
                            class="markdown-body"
                            :options="VueShowdownOptions"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-subtitle>
                <v-row align="center" class="mx-0" justify="center">
                    <v-col cols="12" sm="9">
                        <v-divider class="pb-5"></v-divider>
                        <label v-for="(tag, i) in tags" :key="i">
                            <v-icon>mdi-tag</v-icon> {{ tag }}
                        </label>
                    </v-col></v-row
                >
            </v-card-subtitle>
        </v-card>
        <div>
            <v-btn fab class="red white--text" fixed @click="$router.go(-1)" right top>
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
    </v-row>
</template>

<script>
import { VueShowdown } from "vue-showdown";
import hljs from "highlight.js";
import "highlight.js/styles/xt256.css";
import "@/assets/css/markdown.css";
import axios from "axios";

const highlightCode = () => {
    const preEl = document.querySelectorAll("pre code");

    preEl.forEach((el) => {
        hljs.highlightElement(el);
    });
};

export default {
    name: "NoteDetail",
    components: {
        VueShowdown
    },
    data: () => ({
        VueShowdownOptions: { emoji: true, tables: true },
        skeleton: true,
        mdSource: "",
        fab: false,
        title: "",
        imageNote: "",
        author: "",
        date: "",
        category: "",
        tags: []
    }),
    mounted() {
        highlightCode();
    },
    updated() {
        highlightCode();
    },
    created() {
        let idNote = this.$route.params.id;
        axios.get("/api/notes/" + idNote).then((note) => {
            this.imageNote = this.getImageURL(note.data.img);
            this.mdSource = note.data.content;
            this.title = note.data.title;
            this.author = note.data.author;
            this.date = note.data.date;
            this.category = note.data.category;
            this.tags = JSON.parse(atob(note.data.tags));
            document.title = this.title + " | TukangKetik";
            this.skeleton = false;
        });
    },
    methods: {
        getImageURL(img) {
            return "/assets/img/" + img;
        }
    }
};
</script>

<style scoped>
.markdown-body {
    margin: auto;
}
.row {
    margin-left: 0;
    margin-right: 0;
}
</style>
